.TimelineCheckboxes {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	position: absolute;
	z-index: 1000;
	padding-left: 10px;
}

.TimelineCheckboxes {
	cursor: pointer;
}

.TimelineBackground {
	position: absolute;
	z-index: 1;
}

.ground-overlay__times {
	position: fixed;
	bottom: 1px;
	left: 50%;
	background: white;
	border: 1px solid black;
	padding: 10px;
}

.MapTimeline {
	position: fixed;
	bottom: 0;
	background: white;
	width: 100%;
	height: 200px;
}

.MapTimeline, .MapTimeline * {
	box-sizing: border-box;
}

.MapTimeline .TimelineData {
	z-index: 10;
}

.MapTimeline .TimelineCursor {
	z-index: 15;
}

.MapTimeline .TimelineControls {
	box-sizing: border-box;
}
