html, body, #root, .App {
	height: 100%;
}

.App {
	display: flex;
	flex-direction: column;
}

.App-header {
	background-color: #222;
	height: 32px;
	padding: 5px;
	color: white;
	flex-grow: 0;
	flex-shrink: 0;
}

.App-container {
	flex-grow: 1;
	flex-shrink: 0;
}

.App-title {
	font-size: 1em;
}

.App-intro {
	font-size: large;
}

.MapContainer {
	position: relative;
}
